import React, { useState } from "react";
import classNames from "classnames";

const imageWidths = ["700", "1000", "1500", "2250"];

type Props = {
  className?: string;
  sizes?: string;
  src?: string;
  alt?: string;
  asset?: StoryblokAsset;
} & ({ asset: StoryblokAsset } | { src: string });

export default function StoryblokImage({
  className,
  sizes,
  src,
  alt,
  asset,
}: Props): React.ReactElement {
  const [isLoaded, setIsLoaded] = useState(false);

  src = src || asset?.filename;
  alt = alt || asset?.alt;

  if (!src) {
    return <></>;
  }

  const lowerCaseSrc = src.toLowerCase();
  const isVideo =
    lowerCaseSrc.endsWith(".mp4") || lowerCaseSrc.endsWith(".mov");

  const width = src.split("/")[5].split("x")[0];
  const height = src.split("/")[5].split("x")[1];
  const srcset = imageWidths.map((w) => {
    const url = new URL(src);
    url.hostname = "img2.storyblok.com";
    url.pathname = `${w}x0${url.pathname}`;

    return `${url.href} ${w}w`;
  });

  return (
    <figure className={className}>
      {!isVideo && (
        <img
          className={classNames({
            "is-loaded": isLoaded,
          })}
          src={src}
          srcSet={srcset.join(", ")}
          alt={alt}
          loading="lazy"
          width={width}
          height={height}
          sizes={sizes}
          onLoad={() => {
            setTimeout(() => setIsLoaded(true), 0);
          }}
        />
      )}
      {isVideo && (
        <video
          className={classNames({
            "is-loaded": isLoaded,
          })}
          src={src}
          width={width}
          height={height}
          autoPlay
          loop
          muted
          playsInline
          onLoadedData={() => {
            setTimeout(() => setIsLoaded(true), 0);
          }}
        />
      )}
    </figure>
  );
}
